body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.styled {
  color: #fff;
  background-color: #3f51b5;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
  padding: 7px;
}

.styled:hover {
  background-color: #303f9f;
}

.styled:active {
  box-shadow: inset -2px -2px 3px rgba(255, 255, 255, 0.6),
    inset 2px 2px 3px rgba(0, 0, 0, 0.6);
}

.content_image {
  background-color: #fafafa;
  margin: 0 0 1rem;
  height: 10rem;
  display: flex;
  /* align-items por defecto tiene el valor `stretch` */
  align-items: start;
  justify-content: center;
  align-items: center;
}

.margin-left-40px {
  margin-left: 40px;
}

.btn-edit {
  background-color: #f0e800 !important;
}

.color-link {
  color: #3f51b5;
}
.margin-b-20 {
  margin-bottom: 20px;
}
.margin-t-20 {
  margin-top: 20px;
}
.margin-t-5 {
  margin-top: 5px;
}

.padding-left-35 {
  padding-left: 35px;
}

.statusTrue {
  color: green;
}

.statusFalse {
  color: red;
}

.padding-left-right-25 {
  padding: 0px 25px;
}

.background-table {
  background-color: #fafafa;
}
.width100 {
  width: 100%;
}

.btn-edit-or-create {
  background-color: green;
}

.project-incomplete {
  background-color: red !important;
}

.f-direction-row {
  flex-direction: row;
  align-items: center;
  display: flex;
}

.icon-success {
  color: green;
}

.error-message {
  background-color: tomato;
}

.MuiCardHeader-title {
  font-size: 20px !important;
}

.root-grid-list {
  display: "flex";
  flex: "wrap";
  justify-content: "space-around";
  overflow-y: scroll;
  overflow-x: hidden;
  height: 400px;
}

.gridList {
  width: 500;
  height: 450;
}

.a {
  color: red;
}

.text-field--white .MuiFormLabel-root,
.text-field--white .MuiInputBase-input {
  color: #fff;
}

.text-field--white .MuiInput-underline:before {
  border-color: #fafafa;
}

.box-shadow-0 {
  box-shadow: none !important;
}

.card-icon {
  width: 150px;
}

.card-media-icon {
  width: auto !important;
}

.paddingHeight {
  padding-bottom: 0px !important;
  padding-top: 0px !important;
  padding: 5px !important;
}

.flat-button-file {
  border: none;
  background-color: #cccccc;
  padding: 7px 13px;
  font-size: 16px;
  cursor: pointer;
  display: inline-block;
}

.s3Button {
  display: none !important;
}

.padding-10 {
  padding: 10px !important;
}
.jss1{
  min-height: auto!important;
  background: none!important;
}